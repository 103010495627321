<template>
  <v-card>
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner">
        <v-card elevation="5" color="">
          <v-card-title class="d-flex align-center justify-center">
            <v-img
              :src="require('@/assets/images/logos/anavel_logo.png')"
              max-height="350px"
              max-width="170px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>
          </v-card-title>

          <v-row class="pa-5">
            <v-col class="text-center">
              <v-alert icon="mdi-wifi-off" type="error" prominent dense text
                >Nous n'arrivons pas à joindre internet correctement. <br />
                Merci de réessayer ultérieurement</v-alert
              >
            </v-col>
          </v-row>
          <v-card-text class="text-center"
            ><v-btn color="EoleYellow" class="white--text" outlined @click="reloadPage"
              ><v-icon left>mdi-reload</v-icon> Recharger la page</v-btn
            ></v-card-text
          >
        </v-card>
      </div>
    </div>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import "@mdi/font/css/materialdesignicons.css" // Load icons

export default {
  data: () => ({}),
  methods: {
    reloadPage() {
      if (navigator.onLine) {
        this.$router.push("/dashboard").catch(() => {})
      } else {
        window.location.reload()
      }
    },
  },
}
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";
</style>
